<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
            <SideMenu :indexsec="7"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
           
            <form
              @submit.prevent="
                submit(
                 data.title,
                 file1,
                 autor_form,
                 date_form,
                 data.content
                )
              "
            >
            <div class="div_EProduct">
              <p>EDITAR BLOG</p>
              </div>

              <a class="btn-ver" :href="'/blog/titulo/' + data.url" target="_blank"> VER ENTRADA <img src="../../assets/Arrow_6.png" alt=""> </a>
<div class="mtrow"></div>

              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Título</label>
                  <b-form-input
                    
                    v-model="data.title"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>

                <div class="div-image QUITLEFT">
                  <label class="labelImg color-1" for="inputImg">Imagen cabecera</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                    
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div>

                 
                
              </div>

                <div class="row mx-0 row-two">
                  <div class="EPF1_C1">
                      <label class="labelR color-1" for="inputR">Autor</label>
                    
                      <b-dropdown>
                        <template #button-content>
                          <div class="row"><span class="drop-text">{{data.autor}}</span></div> 
                        </template>

                        <div v-for="(item, key) in this.users" :key="key">
                            <b-dropdown-item @click="changeAutor(item.id, item.name)">
                              {{item.name}}
                            </b-dropdown-item>
                        </div>
                      </b-dropdown>
                    </div>
                    <div class="">
                  <label class="labelA color-1" for="inputA"
                    >Fecha <span class="f_actual">actual: {{data.date}}</span></label
                  >
                  <b-form-input
                    v-model="date_form"
                    
                    id="inputA"
                    type="date"
                   />
                  </div>
                </div>


            <label class="labelA color-1 mtrow EPF1_C1centrado">Contenido</label>
             <vue-editor
                 id="editor"
                 class="quill-editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="data.content"
                >
              </vue-editor> 
              
                <!--   -->


               <div v-if="  this.section =='blog'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="agregarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>

             <!--- videos  -------------------------------------------------------------------------------------------------------------------------------------->
        <hr class="mtrow">
                  <label id="Label_AgrCanales" class="labelpr color-1" >Agregar Categorias</label>

                  <div v-if="this.section =='categorias'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>
                
                  <form
              @submit.prevent="
                agregarCategoria(
                  category_form
                )
              "
            >
              
              


              <div class="row mx-0 row-two">
               
                <div class="div_Canal">
                    <label id="label_subtitle" class="labelEq color-1" >Categoría</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{category_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.categories" :key="key">
                          <b-dropdown-item @click="changeCategory(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>

              </div>

             


             
              <div id="EditPartbtn_Agregar" class="btn-form-2">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal"
                  type="submit"
                  >AGREGAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="wait()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
         
           <div class="canales">
                    <div id="div_canales" class="row channel_titles">
                       <div>
                          <p>ID</p>
                        </div>
                        <div>
                          <p>Nombre</p>
                        </div>
                       
                        <div>
                          <p>Eliminar</p>
                        </div>
                    </div>
                 
                  <div v-if="data.categories != 'No se encontraron coincidencias'">
                  <div  v-for="(item,key) in data.categories" class="item_channel" :key="key">
                        <div class="row">
                          <div>
                         <p>{{item.id}}</p>
                          </div>
                            <div>
                              <p>{{item.category_name}} </p>
                            </div>
                           
                            <div>
                              <p class="eliminar-channel" @click="eliminarCategoria(item.id)">&#10006;</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                        <div class="mtrow msg_channels">
                            <p>Este blog aun no tiene categorias asignadas.</p>
                        </div>
                  </div>
                   
                    
           </div>

             
              
        
            
              
            
            
           


              <label h2 id="prev-title" class="pvz-title color-1 EPF1_C1centrado">Previsualización</label>
              <div id="prev_quill" v-html="data.content">
              </div>

             <label id="prev-title" class="html-title color-1 EPF1_C1centrado">HTML</label>
             <div id="prev_quill">
               {{data.content}}
             </div>
  
             
          
           <!--- Modal de errores  --->
          <b-modal
            id="modal-error"
            ref="modal-error"
            no-close-on-backdrop
            centered
            hide-footer
            hide-header
          >
            <div class="message-modal">
              <img :src="closeIcon" class="icon3 pt-5"><br>
              <p class="text-modal">{{msg}}</p>
            </div>
            <div class="">
              <b-button class="btn-modal" @click="wait()"
                >Aceptar</b-button
              >
              <b-button
                type="button"
                aria-label="Close"
                class="close-modal"
                @click="wait()"
                >×</b-button
              >
            </div>
          </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import Header from '../../components/Header';
import i_logout from '../../assets/iconocerrar.png';
import { mapActions } from "vuex";

import { VueEditor } from "vue2-editor";



var toolbarOptions = [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [ 'link','image' ,'video' ],          // add's image support
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
            ];
export default {
  name: "AddItem",
  components: {
    Header,
    VueEditor,
    SideMenu,
  },

  data() {
    return {
      autor_form:"",
      autor_name:"Autor",
      date_form:'',
      title_form:"",
      status: "",
      msg: "",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      closeIcon: i_logout, 

                 //editor
      content: "",
      editorOption: { 
          modules: {
                    toolbar: toolbarOptions
                },

                theme: 'snow'
        }, // Elemento de configuración del editor 
      section:'',
      category_form :'',
      category_name: 'categoría',
      message_modal:'',
       //pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}{/()+*-_}"
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
     this.getUsers('usuarios')
      let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "blog",
    };
    this.getInfoById(payload);

      this.getCategories('Categorías')
    
  },
  computed:{
      users() {
          return this.$store.getters["users/data"];
        },
      url() {
        return this.$store.getters["main/baseURL"];
      },
      data() {
         return this.$store.getters["main/data"];
      },
    user() {            
        return this.$store.getters['admin/getIdentity'];
    },
    categories() {
       let allctg =this.$store.getters["categories/data"];
       let result =[]
       let model;
        for(var i =0; i<allctg.length; i++){
         if(allctg[i].parent_name != 'TIENDA' && allctg[i].name != 'TIENDA' && allctg[i].name != 'ROPA' && allctg[i].parent_name != 'ROPA'){ //Mostrar solo categorias para ropa.
           if(allctg[i].id== 1){
             model ={
                        id: allctg[i].id,
                        id_parent: allctg[i].id_parent,
                        name: 'NINGUNA',
                        parent_name: allctg[i].parent_name
                      }
          }else{
            model ={
            id: allctg[i].id,
            id_parent: allctg[i].id_parent,
            name: allctg[i].name,
            parent_name: allctg[i].parent_name
            }
          }

           result.push(model)
         }
          
         
       }

      return result
    },
       
       
  },
  methods: {
     ...mapActions('users', ['getUsers']),
      ...mapActions("main", ["getInfoById"]),
       ...mapActions('categories', ['getCategories']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
     showModalError() {
            this.$refs["modal-error"].show();
         },
    eliminarCategoria: async function(id){
      
      let option ='blog/category'
      let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
              
      if(result.status=='error'){
        this.section='categorias'
        this.msg = result.message;
        this.showModalError();
      }else{
        this.section==''
        this.msg='';
        this.wait()
      }
    },
    agregarCategoria: async function(id_category){
        let id_blog = this.$route.params.id;
       this.message_modal ='La categoria se agrego correctamente.'

        /*
        console.log(id_blog)
        console.log(id_category)*/

        if(id_category == '' || id_category == undefined || id_category==null){
                this.section='categorias'
               this.status='error'
              this.msg='Elige una categoría.'
        }else{

          id_blog= String(id_blog)
          id_category = String(id_category)
          let data={
            "id_category":id_category,
            "id_blog":id_blog
          }

          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'blog/category', item: data});
        
          if(result.status=='error'){
            this.section='categorias'
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal() 
          }
        }
    },
    submit: async function (  title, image, autor, date, content) {
     
     this.message_modal ='La entrada se edito correctamente.'
          this.status=''
          this.msg=''

        //  console.log(date)

       
        let id = this.$route.params.id;
        let entrada = this.data;

         let fecha=''
        if(date==''){
          fecha = entrada.date
        }else{
          fecha = date
        }
        /*else{
        fecha = date.split('-')
              let yy = fecha[0]
              let mm = fecha[1]
              let dd = fecha[2]
               fecha= dd+'/'+mm+'/'+yy
        }*/


        date = fecha;

        if(autor=='' || autor == undefined || autor==null){
            autor = entrada.id_user
        }

        id= String(id);
        autor = String(autor)


        if(content =='' || content ==undefined || content==null){
          this.status='error'
          this.msg='El contenido de la entrada está vacío o no es valido.'
        }else if(image == null){
                  let data ={
                      'id':id,
                     'title':title,
                    'dateblog': date,
                    'content':content,
                    'id_user':autor,
                  }

                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/editItem",  {option:'blog', item: data});
                
                  if(result.status=='error'){
                      this.section='blog'
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.showModal() 
                  }
        }else{

            if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
                var data = new  FormData();
              
                  data.append('image', image);
                  data.append('title',title);
                  data.append('dateblog', date);
                  data.append('content',content);
                  data.append('id_user',autor)
                  data.append('id',id)
                  data.append('_method', 'PUT');
                
                      
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/editItem",  {option:'blog', item: data});
                
                  if(result.status=='error'){
                    this.section='blog'
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.showModal() 
                  }

            }
                

        }

    },
    agregarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
    showModal2(){
       this.$refs['modal-editar2'].show()
    },
     changeAutor: function (value, name) {
     
       this.autor_form = value;
       this.data.autor = name;

    },



    handleImageAdded: async function(file, Editor, cursorLocation, resetUploader) {
     
        let url

         var data = new  FormData();
          data.append('image', file );
          data.append('_method', 'POST');

          let result = await  this.$store.dispatch("main/uploadImage",  {option:'blog', data:data});
            console.log(result)
              if(result.data.status=='error'){
                this.status='error'
                this.msg= 'Imagen no valida.'
              }else{ // success
                   url = this.url + '/get-blog-image/' + result.data.fileName
                   Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
              }
    
    },



      changeCategory: function (value, name) {
     
       this.category_form = value;
       this.category_name = name;

      },

      filterTitle: function(title) {
      let result = title.replaceAll(" ", "-").replaceAll('/','_');
      return result;
    },

    

  },
};
</script>
